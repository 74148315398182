export const fields = [
    { key: 'plan', label: "Perencanaan", _style: 'min-width:100px' },
    { key: 'schedule', label: "Jadwal", _style: 'min-width:100px' },
    { key: 'type', label: "Tipe Anggaran", _style: 'min-width:75px' },
    { key: "created_at", label: "Dibuat", _style: 'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px' }
];

export const fields_no_action = [
    { key: 'plan', label: "Perencanaan", _style: 'min-width:100px' },
    { key: 'schedule', label: "Jadwal", _style: 'min-width:100px' },
    { key: 'type', label: "Tipe Anggaran", _style: 'min-width:75px' },
    { key: "created_at", label: "Dibuat", _style: 'min-width:100px' },

];